'use client';

import { EventsCarouselSkeleton } from '@v2/components/EventsCarouselSkeleton/EventsCarouselSkeleton';
import { getCityLatLonByName } from '@v2/services/getCityLatLonByName';
import { getEventsByLocation } from '@v2/services/getEventsByLocation';
import { LeagueCarouselEvents } from '@v2/views/events';
import { usePathname } from 'next/navigation';
import React, { useEffect, useState } from 'react';

type cords = {
  latitude?: number;
  longitude?: number;
};

interface Props {
  location?: string;
  initialEvents: any;
}

export const EventsCarousel = React.memo(
  ({ location, initialEvents }: Props) => {
    const [events, setEvents] = useState<any>();
    const [isLoading, setIsLoading] = useState(false);

    const pathname = usePathname();

    const isConcertsPage = pathname === '/concerts';

    useEffect(() => {
      const getEvents = async () => {
        setIsLoading(true);

        try {
          const cityData = await getCityLatLonByName(location || '');

          if (!cityData) return;

          const response = await getEventsByLocation({
            serverSide: false,
            lat: cityData?.Geometry.lat,
            lon: cityData?.Geometry.lon,
            perPage: 50,
            concerts: isConcertsPage,
          });

          setEvents(response.events);
        } catch (e) {
          console.error(e);
        } finally {
          setIsLoading(false);
        }
      };
      if (location) {
        getEvents();
      }
    }, [location]);

    return (
      <>
        {isLoading ? (
          <EventsCarouselSkeleton withTitle={false} />
        ) : (
          <LeagueCarouselEvents events={events || initialEvents} />
        )}
      </>
    );
  }
);
