import { getLatLng, searchLocation } from '@utils/location';

export const getCityPageData = async (city: string) => {
  const locationResponse = await searchLocation(city);

  const [cityData] = locationResponse.filter(
    (item) =>
      item?.Categories?.includes('MunicipalityType') ||
      item?.Categories?.includes('NeighborhoodType') ||
      item?.Categories?.includes('StreetType') ||
      item?.Categories?.includes('PointOfInterestType')
  );

  if (!cityData) {
    return null;
  }

  const { Geometry, Label, Municipality, Region } = await getLatLng(
    cityData.PlaceId
  );

  return {
    Geometry,
    Label,
    Municipality,
    Region,
    PlaceId: cityData.PlaceId,
    Categories: cityData.Categories,
  };
};
