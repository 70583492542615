'use client';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { searchLocation } from '@utils/location';
import { AWSLocationResponse } from '@v2/types';
import {
  ChevronDown,
  ChevronUp,
  Close,
  MarkerPin01,
  NavigationPointerOff02,
  SearchMD,
} from '@v2/ui';
import { Spinner } from '@v2/ui/Icon/Spinner/Spinner';
import { getCityPageData } from '@v2/views/city/services/getCityPageData';
import clsx from 'clsx';
import { useCallback, useState } from 'react';
import { useDebounceCallback } from 'usehooks-ts';

const defaultValue = {
  Text: 'New York, NY, United States',
};

const getCityName = (location: string) => {
  return location.split(',')[0];
};

interface Props {
  onLocationChange: (location: AWSLocationResponse) => void;
  selectedLocation?: AWSLocationResponse;
  variant?: 'carousel' | 'list';
}

export const EventLocationDropdown = ({
  variant = 'carousel',
  selectedLocation,
  onLocationChange,
}: Props) => {
  //const [selectedPlace, setSelectedPlace] = useState<AWSLocationResponse>();
  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [searchResults, setSearchResults] = useState<AWSLocationResponse[]>([]);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingLocation, setIsLoadingLocation] = useState(false);
  const [isLocationDeniedOrBlocked, setIsLocationDeniedOrBlocked] =
    useState(false);

  const isCarouselVariant = variant === 'carousel';

  const [userLocationItem, setUserLocationItem] =
    useState<AWSLocationResponse>();

  const debounceSearch = useDebounceCallback((searchValue: string) => {
    getAwsLocations(searchValue);
  }, 1500);

  const onChangeSearchInput = useCallback(async (value: string) => {
    if (!value.trimStart().length) setSearchResults([]);
    setSearchValue(value);
    if (value.trimStart().length < 3) return;
    debounceSearch(value.trimStart());
  }, []);

  const getAwsLocations = async (location: string) => {
    setShowErrorMessage(false);
    setIsLoading(true);
    const result = await searchLocation(location);
    if (!result) {
      setIsLoading(false);
      setShowErrorMessage(true);
      return;
    }

    const filterOnlyCities = result.filter(
      (item) =>
        item?.Categories?.includes('MunicipalityType') ||
        item?.Categories?.includes('NeighborhoodType') ||
        item?.Categories?.includes('StreetType')
    );

    if (!filterOnlyCities.length) {
      setShowErrorMessage(true);
      setIsLoading(false);
    }

    setSearchResults(filterOnlyCities);
    setIsLoading(false);
  };

  const clearSearch = () => {
    setSearchValue('');
    setSearchResults([]);
    setShowErrorMessage(false);
  };

  const onSelectLocation = (item: AWSLocationResponse) => {
    onLocationChange(item);
  };

  const getUserLocation = async () => {
    setIsLoadingLocation(true);
    const options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0,
    };
    const success = async (pos) => {
      const crd = pos.coords;
      const result = await searchLocation(`${crd.latitude},${crd.longitude}`);

      const cityData = await getCityPageData(result[0].Text);
      if (!cityData) {
        setIsLoadingLocation(false);
        return setShowErrorMessage(true);
      }

      const item = { ...cityData, Text: cityData.Municipality };

      onSelectLocation(item);
      clearSearch();
      setIsLoadingLocation(false);
      setIsOpen(false);
    };

    const errors = (err) => {
      console.warn(`ERROR(${err.code}): ${err.message}`);
      setIsLoadingLocation(false);
      setIsLocationDeniedOrBlocked(true);
    };
    if (navigator.geolocation) {
      navigator.permissions
        .query({ name: 'geolocation' })
        .then(function (result) {
          if (result.state === 'granted') {
            //If granted then you can directly call your function here
            navigator.geolocation.getCurrentPosition(success, errors, options);
          } else if (result.state === 'prompt') {
            //If prompt then the user will be asked to give permission
            navigator.geolocation.getCurrentPosition(success, errors, options);
          } else if (result.state === 'denied') {
            setIsLocationDeniedOrBlocked(true);
            setIsLoadingLocation(false);
            //If denied then you have to show instructions to enable location
          }
        });
    } else {
      setIsLocationDeniedOrBlocked(true);
      setIsLoadingLocation(false);
    }
  };

  const listButtonClassname = clsx(
    'flex w-full shrink-0 min-w-48 md:min-w-fit border border-gray-200 rounded-lg items-center gap-x-2 h-9 md:h-12 px-4',
    {
      'bg-gray-800 text-white': !!selectedLocation,
    }
  );

  return (
    <div className={isCarouselVariant ? 'flex flex-row' : 'w-full'}>
      {isCarouselVariant && (
        <h1 className="mb-6 text-lg font-bold md:text-2xl">Events near</h1>
      )}
      <DropdownMenu.Root
        open={isOpen}
        onOpenChange={(open) => setIsOpen(open)}
        modal={false}
      >
        <DropdownMenu.Trigger asChild>
          {isCarouselVariant ? (
            <button
              type="button"
              className="ml-2 flex h-7 min-w-fit cursor-pointer items-center justify-center gap-2 text-lg font-bold text-primary-500 outline-none md:h-8 md:text-2xl"
            >
              {getCityName(selectedLocation?.Text || defaultValue.Text)}
              {isOpen ? <ChevronUp size="20" /> : <ChevronDown size="20" />}
            </button>
          ) : (
            <button className={listButtonClassname}>
              <>
                {selectedLocation ? selectedLocation.Text : `Choose location`}
                {isOpen ? <ChevronUp size="20" /> : <ChevronDown size="20" />}
              </>
            </button>
          )}
        </DropdownMenu.Trigger>

        <DropdownMenu.Portal>
          <DropdownMenu.Content
            align={isCarouselVariant ? 'center' : 'start'}
            className="mt-4 rounded-xl bg-white shadow-xl"
          >
            <div className="flex h-16 items-center justify-between gap-5 border-b border-gray-200 px-5 text-gray-500">
              <SearchMD />
              <input
                className="h-full min-w-64 flex-1 outline-none md:min-w-72"
                placeholder="Search by city"
                type="text"
                value={searchValue}
                onChange={(e) => onChangeSearchInput(e.target.value)}
              />
              <button onClick={() => setIsOpen(false)}>
                <Close />
              </button>
            </div>
            {showErrorMessage && (
              <div className="flex flex-col items-center justify-center gap-2 py-10">
                <MarkerPin01 size="24" className="text-gray-500" />
                <h3 className="font-semibold text-gray-500">
                  No locations found
                </h3>
                <p className="w-full max-w-80 text-center text-gray-500">
                  We couldn&apos;t find this location. Please select another
                  location or try again later.
                </p>
              </div>
            )}
            {isLoading && (
              <div className="flex w-full flex-col items-center justify-center gap-2 py-12">
                <div role="status">
                  <Spinner size="24" />
                </div>
                <p className="w-full max-w-80 text-center text-sm text-gray-500">
                  Loading locations
                </p>
              </div>
            )}
            {searchResults.map((item) => (
              <DropdownMenu.Item
                key={item.PlaceId}
                onSelect={() => {
                  onSelectLocation(item);
                  clearSearch();
                }}
                className="flex h-16 w-full cursor-pointer items-center gap-3 px-6 py-3 font-semibold text-gray-800 outline-none hover:bg-gray-100"
              >
                {item.Text}
              </DropdownMenu.Item>
            ))}
            {(!searchValue || !searchResults.length || isLoading) && (
              <button
                disabled={isLoadingLocation || isLocationDeniedOrBlocked}
                onClick={getUserLocation}
                className="flex h-16 w-full cursor-pointer items-center gap-3 border-t border-gray-200 px-6 py-3 font-semibold text-gray-800 outline-none hover:bg-gray-100"
              >
                {isLoadingLocation && (
                  <>
                    <Spinner size="24" />
                    Loading location
                  </>
                )}
                {!isLoadingLocation && isLocationDeniedOrBlocked && (
                  <>
                    <NavigationPointerOff02 />
                    Your location is not available
                  </>
                )}
                {!isLoadingLocation && !isLocationDeniedOrBlocked && (
                  <>
                    <MarkerPin01 />
                    {userLocationItem?.Text.split(', ')[3] || `Use my location`}
                  </>
                )}
              </button>
            )}
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>
    </div>
  );
};
