'use client';
import { FollowList } from '@interfaces/auth';

import { useSession } from 'next-auth/react';
import { useState } from 'react';
import { TeamItem } from './TeamItem';

interface Props {
  teams: any[];
  leagueName: string;
  favorites?: FollowList;
  userToken?: string;
}

export const AllLeagueTeams = ({
  teams,
  leagueName,
  favorites,
  userToken,
}: Props) => {
  const [favoritiesList, setFavoritiesList] = useState(favorites);

  const { update } = useSession();

  const onFavoriteUpdate = async (item: FollowList[number]) => {
    await update({
      follow: [
        ...(favoritiesList || []),
        { id: item.id, type: 'p', name: item.name },
      ],
    });
    setFavoritiesList((oldValue) => {
      return [
        ...(oldValue || []),
        { id: item.id, type: 'performer', name: item.name },
      ];
    });
  };

  const onRemoveFavoriteUpdate = async (item: FollowList[number]) => {
    const removedFollowList = favoritiesList?.filter(
      (fav) => fav.id !== item.id
    );
    await update({
      follow: removedFollowList,
    });

    setFavoritiesList(removedFollowList);
  };

  if (!teams.length) return null;

  return (
    <div>
      <h1 className="mb-8 text-2xl font-bold text-gray-800">
        All {leagueName} teams
      </h1>
      <div className="grid-flow-rows grid w-full auto-rows-max grid-cols-2 gap-6 lg:grid-cols-6">
        {teams.map((item) => {
          return (
            <div key={item.division} className="w-full">
              <h4 className="mb-2 text-sm text-gray-500">{item.division}</h4>
              <ul>
                {item.teams.map((team: any) => {
                  const isFavorite = favoritiesList?.find(
                    (favorite) => favorite.id === team.teamId
                  );
                  return (
                    <TeamItem
                      key={team.teamId}
                      isFavorite={!!isFavorite}
                      team={team}
                      userToken={userToken}
                      onFavoriteUpdate={onFavoriteUpdate}
                      onRemoveFavoriteUpdate={onRemoveFavoriteUpdate}
                    />
                  );
                })}
              </ul>
            </div>
          );
        })}
      </div>
    </div>
  );
};
