import { getLatLng, searchLocation } from '@utils/location';
import { getTopDestinations } from './getTopDestinations';

export const getCityLatLonByName = async (city: string) => {
  const topDestinations = getTopDestinations();

  const parsedCity = city.split(',')[0].trim();

  const [filteredTopDestination] = topDestinations.filter((destination) =>
    destination.name.toLowerCase().startsWith(parsedCity.toLowerCase())
  );

  if (filteredTopDestination) {
    return {
      Geometry: {
        lat: filteredTopDestination.lat,
        lon: filteredTopDestination.lon,
      },
      Label: filteredTopDestination.name,
    };
  }

  const searchResults = await searchLocation(city);

  const [cityData] = searchResults.filter((result) => result?.PlaceId);

  if (!cityData) {
    return null;
  }

  const { Geometry, Label } = await getLatLng(cityData.PlaceId);
  const [lon, lat] = Geometry.Point;
  return {
    Geometry: {
      lat,
      lon,
    },
    Label,
  };
};
