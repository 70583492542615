export const getTopDestinations = () => {
  return [
    {
      id: '1',
      name: 'Toronto, CA',
      url: '/city/toronto-on-canada',
      imageUrl: '/destinations/toronto.webp',
      lat: 43.7,
      lon: -79.42,
    },
    {
      id: '2',
      name: 'New York, NY',
      url: '/city/new-york-ny-united-states',
      imageUrl: '/destinations/new-york.webp',
      lat: 40.7,
      lon: -74.0,
    },
    {
      id: '3',
      name: 'Los Angeles, CA',
      url: '/city/los-angeles-ca-united-states',
      imageUrl: '/destinations/los-angeles.webp',
      lat: 34.05,
      lon: -118.24,
    },
    {
      id: '4',
      name: 'Las Vegas, NV',
      url: '/city/las-vegas-nv-united-states',
      imageUrl: '/destinations/las-vegas.webp',
      lat: 36.17,
      lon: -115.14,
    },
    {
      id: '5',
      name: 'Dallas, TX',
      url: '/city/dallas-tx-united-states',
      imageUrl: '/destinations/dallas.webp',
      lat: 32.78,
      lon: -96.8,
    },
    {
      id: '6',
      name: 'Chicago, IL',
      url: '/city/chicago-il-united-states',
      imageUrl: '/destinations/chicago.webp',
      lat: 41.88,
      lon: -87.63,
    },
    {
      id: '7',
      name: 'Boston, MA',
      url: '/city/boston-ma-united-states',
      imageUrl: '/destinations/boston.webp',
      lat: 42.36,
      lon: -71.06,
    },
    {
      id: '8',
      name: 'Miami, FL',
      url: '/city/miami-fl-united-states',
      imageUrl: '/destinations/miami.webp',
      lat: 25.76,
      lon: -80.19,
    },
    {
      id: '9',
      name: 'New Orleans, LA',
      url: '/city/new-orleans-la-united-states',
      imageUrl: '/destinations/new-orleans.webp',
      lat: 29.95,
      lon: -90.07,
    },
    {
      id: '10',
      name: 'Nashville, TN',
      url: '/city/nashville-tn-united-states',
      imageUrl: '/destinations/nashville.webp',
      lat: 36.16,
      lon: -86.78,
    },
  ];
};
