interface Props {
  withTitle?: boolean;
}

export const EventsCarouselSkeleton = ({ withTitle = true }: Props) => {
  return (
    <div className="animate-pulse opacity-15">
      {withTitle && (
        <div className="mb-6 h-8 w-64 rounded-lg bg-gray-200"></div>
      )}

      <div className="no-scrollbar grid auto-cols-auto grid-flow-col gap-6 overflow-scroll">
        {Array.from(Array(5), (x, i) => i + 1).map((item) => (
          <div key={item} className="flex w-72 flex-col">
            <div className="mb-3 h-40 w-full rounded-lg bg-gray-200"></div>
            <div className="w-full max-w-64">
              <div className="mb-2 h-9 rounded-lg bg-gray-200"></div>
              <div className="mb-1 h-4 rounded-lg bg-gray-200"></div>
              <div className="h-4 rounded-lg bg-gray-200"></div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
